import * as bootstrap from 'bootstrap';
import 'datatables.net-dt/css/jquery.dataTables.css';
import dt from 'datatables.net-dt';
import $ from 'jquery';
import moment from 'moment';

import { renderDashboard } from '/js/dashboard.js';
import { v1BaseUrl } from '@shared/js/apiV1.js';
import { getAllUsers, getInitials } from '/js/users.js';

export async function eventsTab() {
  const users = await getAllUsers();
  const userNameLookup = createUserLookup(users);

  return new Promise((resolve) => {
	// Create container and table
	const $div = $("<div class='w-100'><div class='w-100 hstack justify-content-between text-light mb-2' id='tch_shows'><h1>Audit Log</h1><div></div>");
	const $table = $("<table class='w-100 table table-striped table-bordered'/>").appendTo($div); // Add Bootstrap classes

	const dataTable = $table.DataTable({
	  stateSave: true,
	  ajax: {
		url: v1BaseUrl + "admin/events/list",
		type: "GET",
		xhrFields: {
		  withCredentials: true
		},
		dataType: "json",
		dataSrc: (data) => {
		  // Sort the data by creation date, newest first
		  data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
		  return generateTableData(data, userNameLookup);
		}
	  },
	  columns: [
		{ title: "Timestamp" },
		{ title: "Actor" },
		{ title: "Event" },
	  ],
	  order: [[ 0, "desc" ]]
	});

	// Auto-reload every 5 seconds
	setInterval(() => dataTable.ajax.reload(null, false), 5000);

	resolve($div);
  });
}

export function createUserLookup(users) {
	// XSS protection
	function escapeHtml(text) {
	  return $("<div>").text(text).html();
	}
	
  const lookup = {};

  users.forEach(user => {
	const fullName = `${escapeHtml(user.given_name)} ${escapeHtml(user.family_name)}`;
	const initials = getInitials(escapeHtml(user.given_name), escapeHtml(user.family_name));
	lookup[user.guid] = { "fullName": fullName, "initals": initials };
  });

  return lookup;
}

export function generateTableData(data, userNameLookup) {
  let tableData = [];
  
  // XSS protection
  function escapeHtml(text) {
	return $("<div>").text(text).html();
  }

  data.forEach((event) => {
	if (event.redeemed_at == null) {
	  event.redeemed_at = "Never";
	}
  
	let actorInfo = userNameLookup[event.actor] || {};
	
	let actorDisplay = actorInfo.fullName || "-";
	
	tableData.push([
	  event.timestamp, escapeHtml(actorDisplay), escapeHtml(event.event)
	]);
  });

  return tableData;
}
