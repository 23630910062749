import '/css/main.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

import $ from 'jquery';
import * as bootstrap from 'bootstrap';

import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://9457ba2b02864861a4b42283898c5c54@o4504990620909568.ingest.sentry.io/4505120704167936",
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new Sentry.Replay({maskAllText: false, maskAllInputs: false, blockAllMedia: false}), new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

import { renderDashboard } from '/js/dashboard.js';
import { authStatus, promptSignIn, userData, updateUserData } from '/js/accounts.js';
import { loader, showLoader, hideLoader } from '/js/navigation.js';
import { svInit, APP_VERSION } from '@shared/js/instance.js';

svInit();

let libraryRendered = false;

// Loader
showLoader();

// Initial authentication check
authStatus(function (isLoggedIn) {
  if (isLoggedIn) {
  	updateUserData().then(() => {
		if (!userData.admin) {
			if (window.location.href.includes("dev-proxy.net")) {
				window.location.href = "https://stagevault.dev-proxy.net";
			} else {
				window.location.href = "https://stagevault.com";
			}
		}
		hideLoader();
		renderDashboard();
		libraryRendered = true;
  	}).catch((error) => {
	  	console.log("Error fetching account info");
  	});
  } else {
	promptSignIn();
  }
  queuePersistentAuthChecks();
});

function queuePersistentAuthChecks() {
	// Persistent authentication check
	setInterval(function () {
	  authStatus(function (isLoggedIn) {
		if (isLoggedIn) {
			if (!userData.admin) {
				if (window.location.href.includes("dev-proxy.net")) {
					window.location.href = "https://stagevault.dev-proxy.net";
				} else {
					window.location.href = "https://stagevault.com";
				}
			}
		  if ($("#authPrompt").length == 1) {
			// destroySignInPrompt();
		  }
		  if (!libraryRendered) {
			renderDashboard();
			libraryRendered = true;
		  }
		} else {
		  if ($("#authPrompt").length == 0) {
			promptSignIn();
		  }
		  libraryRendered = false;
		}
	  });
	}, 5000); // 5000 milliseconds = 5 seconds
}