import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import moment from 'moment';

import { v1BaseUrl } from '@shared/js/apiV1.js';
import { renderDashboard } from '/js/dashboard.js';

export function usersTab() {
  return new Promise((resolve) => {
	$.ajax({
	  url: v1BaseUrl + "admin/users/list",
	  type: "GET",
	  xhrFields: {
		withCredentials: true
	  },
	  dataType: "json",
	  success: function (data) {
		// Sort the data by creation date, newest first
		data.sort((a, b) => new Date(b.created) - new Date(a.created));
		let table = generateUsersTable(data);
		resolve(table);
	  },
	});
  });
}

export function getAllUsers() {
	return new Promise((resolve) => {
		$.ajax({
	  	url: v1BaseUrl + "admin/users/list",
	  	type: "GET",
	  	xhrFields: {
			withCredentials: true
	  	},
	  	dataType: "json",
	  	success: function (data) {
			// Sort the data by creation date, newest first
			data.sort((a, b) => new Date(b.created) - new Date(a.created));
			resolve(data);
	  	},
		});
  	});
}

export function getInitials(givenName, familyName) {
  const firstInitial = givenName ? givenName[0].toUpperCase() : '';
  const lastInitial = familyName ? familyName[0].toUpperCase() : '';
  return `${firstInitial}${lastInitial}`;
}

export const userDetailModal = $(`
  <div class="modal fade" id="userDetailModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-lg">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" id="userDetailModalLabel">Edit User</h5>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
		  <form id="userDetailForm" method="post">
		  	<h5>Personal Info</h5>
			<div class="input-group mt-1 mb-3">
			  <span class="input-group-text">First and last name</span>
			  <input type="text" aria-label="First name" class="form-control" placeholder="First" id="userGivenName" name="name">
			  <input type="text" aria-label="Last name" class="form-control" placeholder="Last" id="userFamilyName" name="name">
			</div>
			<div class="input-group mb-3">
			  <span class="input-group-text" id="basic-addon1">Email</span>
			  <input type="text" class="form-control" placeholder="user@example.com" aria-label="Username" aria-describedby="basic-addon1" id="userEmail" name="email">
			  <br>
			  <span id="resendHint" class="fw-bold text-danger mt-1">This uses does not have a verified email address. Click <a id="resendVerificationLink" href="#">here</a> to create a new verification request. They will recieve an email with a link to verify. If you can manually verify ownership, you will be able to mark their email as verified below after a request is created.</span>
			</div>
			<div class="input-group mb-3">
			  <span class="input-group-text" id="basic-addon2">New Password</span>
			  <input type="text" class="form-control" placeholder="Password will not be changed if left blank" aria-label="New Password" aria-describedby="basic-addon2" id="userPassword" name="password">
			</div>
			<hr/>
			<h5>Roles</h5>
			<div class="mb-3">
			  <input class="form-check-input" type="checkbox" value="" id="userRole">
			  <label class="form-check-label" for="userRole">
				Administrator
			  </label>
			</div>
		  </form>
		  <hr>
		  <div>
			<h5>Associated Email Addresses</h5>
			<span>Any email(s) listed below can be used to login to this user's account. If a user does not have a verifed email address, they cannot utilize most features of the app.</span>
			<table class="table table-striped" id="userEmailTable">
			  <thead>
				<tr>
				  <th>Email</th>
				  <th>Status</th>
				  <th>Added</th>
				  <th>Actions</th>
				</tr>
			  </thead>
			  <tbody></tbody>
			</table>
		  </div>
		</div>
		<div class="modal-footer d-flex justify-content-between">
		  <div class="d-flex justify-content-around flex-column">
		     <span class="text-secondary" id="userGuidLabel"></span>
		     <span class="text-secondary" id="userCreatedLabel"></span>
			 <span class="text-secondary" id="userTermsStatus"></span>
		  </div>
		  <button type="submit" class="btn btn-primary" id="userUpdateButton">Update</button>
		</div>
	  </div>
	</div>
  </div>
`);

export const userVaultModal = $(`
  <div class="modal fade" id="userVaultModal" tabindex="-1" aria-hidden="true">
	<div class="modal-dialog modal-lg">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" id="userVaultModalLabel">Manage Vault</h5>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
		  <span class="fs-4 fw-bold" id="vaultOwnerName"></span><br>
		  <span class="fs-6" id="vaultOwnerEmail"></span>
		  <hr>
		  <table class="table">
			<thead>
			  <tr>
				<th>Cover</th>
				<th>Title</th>
				<th>Code</th>
				<th>Redeemed At</th>
				<th>Actions</th>
			  </tr>
			</thead>
			<tbody id="userVaultTableBody">
			</tbody>
		  </table>
		</div>
		<div class="modal-footer d-flex justify-content-between">
		  <span>Any changes are saved immediately and cannot be reversed. Only users can redeem codes.</span>
		  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
		</div>
	  </div>
	</div>
  </div>
`);

function showUserVaultModal(user) {
	let userGuid = user.guid;
	
  $('body').append(userVaultModal);
	
  $.ajax({
	url: v1BaseUrl + "admin/users/vault",
	type: "POST",
	data: {
	  guid: userGuid
	},
	xhrFields: {
	  withCredentials: true
	},
	dataType: "json",
	success: function (response) {
	  const entitlements = response.entitlements;
	  $('#userVaultTableBody').empty(); // Clear the table
	  
	  $("#vaultOwnerName").text(user.givenName + " " + user.familyName);
	  $("#vaultOwnerEmail").text(user.email);
	
	  entitlements.forEach(entitlement => {
		// Append row to table
		$('#userVaultTableBody').append(createRow(entitlement));
	  });
	
	  const userVaultModalInstance = new bootstrap.Modal(document.getElementById('userVaultModal'));
	  userVaultModalInstance.show();
	  
	  userVaultModalInstance._element.addEventListener('hidden.bs.modal', function (e) {
		// Call the function when the modal is closed
		renderDashboard();
	  });

	  
	},
	error: function () {
	  alert('Failed to load user vault');
	}
  });
}

function createRow(entitlement) {
  const show = entitlement.show;
  const code = entitlement.code;

  // Create table row
  const row = document.createElement('tr');

  // Create cells
  const coverArtCell = document.createElement('td');
  const coverArtImage = document.createElement('img');
  coverArtImage.src = show.coverArt;
  coverArtImage.width = 50;
  coverArtCell.appendChild(coverArtImage);

  const titleCell = document.createElement('td');
  const tcv = document.createElement('div');
  tcv.classList.add('vstack');
  const tcvTitle = document.createElement('span');
  tcvTitle.classList.add('text-light');
  tcvTitle.textContent = show.title;
  const tcvSubtitle = document.createElement('span');
  tcvSubtitle.classList.add('fs-6');
  tcvSubtitle.textContent = show.subtitle;
  tcv.append(tcvTitle);
  tcv.append(tcvSubtitle);
  titleCell.append(tcv);

  const codeCell = document.createElement('td');
  const codeTag = document.createElement('code');
  codeTag.textContent = code.code;
  codeCell.appendChild(codeTag);

  const timestampCell = document.createElement('td');
  timestampCell.textContent = new Date(code.redeemedAt).toLocaleString();

  const actionCell = document.createElement('td');
  const revokeButton = document.createElement('button');
  revokeButton.classList.add('btn', 'btn-danger');
  revokeButton.textContent = 'Release';

  // Add click event to revoke button
  revokeButton.addEventListener('click', function() {
	if (confirm('Are you sure you want to revoke this?')) {
	  $.ajax({
		url: v1BaseUrl + "admin/codes/revoke",
		type: "POST",
		data: {
		  guid: code.guid
		},
		xhrFields: {
		  withCredentials: true
		},
		dataType: "json",
		success: function () {
		  //alert('Code successfully revoked');
		  // Remove the row
		  row.remove();
		  // Refresh the table
		  // showUserVaultModal(user);
		},
		error: function () {
		  alert('Failed to release code');
		}
	  });
	}
  });

  actionCell.appendChild(revokeButton);

  // Append cells to row
  row.appendChild(coverArtCell);
  row.appendChild(titleCell);
  row.appendChild(codeCell);
  row.appendChild(timestampCell);
  row.appendChild(actionCell);

  return row;
}


function showUserDetailModal(userGuid) {
  $.ajax({
	url: v1BaseUrl + "admin/users/detail",
	type: "POST",
	data: {
	  guid: userGuid
	},
	xhrFields: {
	  withCredentials: true
	},
	dataType: "json",
	success: function (data) {
	  $('body').append(userDetailModal); // Append the modal to the body
	  fillUserDetailForm(data.emails, data.account);
	  fillEmailsTable(data.emails, data.account.email, data.account.guid);

	  // Store the account email to be used for resending verification
	  const accountEmail = data.account.email;

	  // Attach click event to resend verification link
	  $('#resendVerificationLink').off('click').on('click', function(e) {
		e.preventDefault(); // This line prevents the default action of the anchor tag
		resendUserEmailVerification(accountEmail)
		  .then(() => {
			alert('A new verification email has been sent to the user.');
			// Call getUserEmailsAndFillTable to update the emails table
			getUserEmailsAndFillTable(userGuid);
		  })
		  .catch((error) => {
			console.error(error);
			alert('Failed to resend verification email to account email');
		  });
	  });

	  
	  $('#userUpdateButton').off('click').on('click', function(e) {
		e.preventDefault();
	  
		const userDetailForm = $('#userDetailForm');
		const givenName = userDetailForm.find('#userGivenName').val();
		const familyName = userDetailForm.find('#userFamilyName').val();
		const userEmail = userDetailForm.find('#userEmail').val();
		const userRole = userDetailForm.find('#userRole').prop('checked') ? 1 : 0;
		const password = userDetailForm.find('#userPassword').val();
	  
		// Validate inputs
		if (givenName === '' || familyName === '' || userEmail === '' || userRole === '') {
		  alert('Please fill all the fields');
		  return;
		}
	  
		const userUpdateData = {
		  guid: userGuid, // userId needs to be available here
		  given_name: givenName,
		  family_name: familyName,
		  email: userEmail,
		  admin: userRole,
		  new_password: password
		};
	  
		$.ajax({
		  url: v1BaseUrl + "admin/users/update",
		  type: "POST",
		  data: userUpdateData,
		  xhrFields: {
			withCredentials: true
		  },
		  dataType: "json",
		  success: function (data) {
			const userDetailModal = bootstrap.Modal.getInstance(document.getElementById('userDetailModal'));
			userDetailModal.hide();
			renderDashboard();
			// alert('User updated successfully');
		  },
		  error: function () {
			alert('Failed to update user');
		  }
		});
	  });
	  
	  const userDetailModalInstance = new bootstrap.Modal(document.getElementById('userDetailModal'));
	  userDetailModalInstance.show();
	},
  });
}

function getUserEmailsAndFillTable(userGuid) {
  $.ajax({
	url: v1BaseUrl + "admin/users/detail",
	type: "POST",
	data: {
	  guid: userGuid
	},
	xhrFields: {
	  withCredentials: true
	},
	dataType: "json",
	success: function (data) {
	  fillEmailsTable(data.emails, data.account.email, data.account.guid);
	},
  });
}

function verifyUserEmail(token) {
	return new Promise((resolve, reject) => {
		$.ajax({
		  url: v1BaseUrl + "admin/users/verify?token=" + token,
		  type: "GET",
		  xhrFields: {
			withCredentials: true
		  },
		  success: function (data) {
			  $('#resendHint').addClass('d-none');
			resolve(true);
		  },
		  error: function () {
			reject(new Error("Failed to verify email"));
		  }
		});
	});
}

function resendUserEmailVerification(email) {
	return new Promise((resolve, reject) => {
		$.ajax({
		  url: v1BaseUrl + "admin/users/resend",
		  type: "POST",
		  xhrFields: {
			  withCredentials: true
			},
		  data: { email },
		  success: function (data) {
			resolve(true);
		  },
		  error: function () {
			reject(new Error("Failed to resend verification"));
		  }
		});
	});
}


function fillUserDetailForm(emails, user) {
  const userDetailForm = $('#userDetailForm');
  userDetailForm.find('#userGivenName').val(user.given_name);
  userDetailForm.find('#userFamilyName').val(user.family_name);
  userDetailForm.find('#userEmail').val(user.email);
  userDetailForm.find('#userPassword').val("");
  
    let termsHasAgreed = user.terms_agreed;
	let termsAgreedAt = user.terms_agreed_at;
	let termsStatus = "";
	
	// Check if the user has agreed to the terms
	if (termsHasAgreed) {
  		// Compare agreement timestamp with creation timestamp
  		if (termsAgreedAt === user.created) {
			termsStatus = "TRUE, agreed during sign up";
  		} else {
			termsStatus = `TRUE, agreed at ${termsAgreedAt}`;
  		}
	} else {
  		termsStatus = "FALSE";
	}
  
  $("#userGuidLabel").text("GUID: " + user.guid);
  $("#userCreatedLabel").text("Created: " + user.created);
  $("#userTermsStatus").text("Terms Agreement: " + termsStatus);

  // map the admin status to checkbox
  let userRole = userDetailForm.find('#userRole');
  userRole.prop('checked', user.admin === 1);

  // set creation date as read-only
  // let userCreationDate = userDetailForm.find('#userCreationDate');
  // userCreationDate.val(moment(user.created).format('YYYY-MM-DD HH:mm:ss'));
  // userCreationDate.attr('readonly', true);
  
  	// Pass the account email to fillEmailsTable
	fillEmailsTable(emails, user.email, user.guid);
}

function fillEmailsTable(emails, accountEmail, userGuid) {
	const userEmailTableBody = $('#userEmailTable tbody');
	userEmailTableBody.empty();
	let isVerifiedAccountEmailFound = false;
	
	emails.forEach((email) => {
		let statusText = email.status === 1 ? 'Verified' : 'Unverified';
		
		// Check if the current email is the account email and is verified
		if (email.email === accountEmail && email.status === 1) {
		  isVerifiedAccountEmailFound = true;
		}

		let verifyButton = '';
		if (email.status !== 1) {
		  verifyButton = `<button class="btn btn-warning btn-verify" data-token="${email.token}">Mark as Verified</button>`;
		}

		let removeButton = `<button class="btn btn-danger btn-remove" data-email="${email.email}">Remove</button>`;

		let row = $(`
		  <tr>
			<td>${email.email}</td>
			<td>${statusText}</td>
			<td>${moment(email.created).format('YYYY-MM-DD HH:mm:ss')}</td>
			<td>${verifyButton} ${removeButton}</td>
		  </tr>
		`);
		userEmailTableBody.append(row);
	});
	
	// If a verified account email was found, disable the resend button
	  if (isVerifiedAccountEmailFound) {
		$('#resendHint').addClass('d-none');
	  } else {
		$('#resendHint').removeClass('d-none');
	  }

	// Attach event to remove button
	userEmailTableBody.find(".btn-remove").on("click", function() {
		const email = $(this).data("email");
	
		removeEmail(email, userGuid)
			.then(() => {
				alert(email + ' has been removed from their account.');
			})
			.catch((error) => {
				console.error(error);
				alert('Failed to remove email.');
			});
	});

	// Attach event to verify button
	userEmailTableBody.find(".btn-verify").on("click", function() {
		const token = $(this).data("token");
		const button = $(this); // Capture the clicked button

		verifyUserEmail(token)
			.then(() => {
				button.remove(); // Remove button on success
			})
			.catch((error) => {
				console.error(error);
				alert('Failed to override verification');
			});
	});
}

function removeEmail(email, userGuid) {
	return new Promise((resolve, reject) => {
		$.ajax({
			url: v1BaseUrl + "admin/users/remove",
			type: "POST",
			data: {
			  email: email
			},
			xhrFields: {
			  withCredentials: true
			},
			dataType: "json",
			success: function (data) {
				getUserEmailsAndFillTable(userGuid);
			  resolve(true);
			},
			error: function () {
				reject(new Error("Failed to remove email."));
		  	}
		  });
	});
}

// XSS protection
function escapeHtml(text) {
  return $("<div>").text(text).html();
}

function generateUsersTable(data) {
  let table = $(`
	  <div class="w-100 hstack justify-content-between text-light mb-2" id="tch_shows"><h1>Users</h1><div><a class="btn btn-primary d-none" id="showUploadBtn"><i class="bi bi-person-fill-add me-1"></i> Create</a></div></div>
	<table class="table table-striped">
	  <thead>
		<tr class="text-light">
		  <th scope="col">Name</th>
		  <th scope="col">Email</th>
		  <th scope="col">Shows</th>
		  <th scope="col">Role</th>
		  <th scope="col">Created</th>
		  <th scope="col">Actions</th>
		</tr>
	  </thead>
	  <tbody></tbody>
	</table>
  `);

  data.forEach((user, index) => {
	  let role = "Unknown";
	  if (user.admin == 1) {
		  role = "Admin";
	  } else {
		  role = "User";
	  }
	let row = $(`
		<tr>
		  <th scope="row" class="text-light">${escapeHtml(user.given_name)} ${escapeHtml(user.family_name)}</th>
		  <td class="text-light">${escapeHtml(user.email)}</td>
		  <td class="text-light">${user.shows}</td>
		  <td class="text-light">${role}</td>
		  <td class="text-light">${moment(user.created).fromNow()}</td>
		  <td>
			<button class="btn btn-danger btn-edit" data-guid="${user.guid}">
			  Edit
			</button>
			<button class="btn btn-primary btn-vault" data-guid="${user.guid}" data-gn="${user.given_name}" data-fn="${user.family_name}" data-email="${user.email}">
			  Manage Vault
			</button>
		  </td>
		</tr>
	  `);
	table.find("tbody").append(row);
  });
  
  	// Attach event to edit button
	table.find(".btn-edit").on("click", function() {
  	const userGuid = $(this).data("guid");
  	showUserDetailModal(userGuid);
	});
	
	// Attach event to vault button
	table.find(".btn-vault").on("click", function() {
	  const userGuid = $(this).data("guid");
	  const userGivenName = $(this).data("gn");
	  const userFamilyName = $(this).data("fn");
	  const userEmail = $(this).data("email");
	  let user = {"guid":userGuid,"givenName":userGivenName,"familyName":userFamilyName,"email":userEmail};
	  showUserVaultModal(user);
	});
  
  return table;
}