import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import moment from 'moment';
import DataTable from 'datatables.net-dt';

import { v1BaseUrl } from '@shared/js/apiV1.js';

export function showsTab() {
  return new Promise((resolve) => {
	$.ajax({
	  url: v1BaseUrl + "admin/shows/list",
	  type: "GET",
	  xhrFields: {
		withCredentials: true
	  },
	  dataType: "json",
	  success: function (data) {
		if (data.result == false) {
			return;
		}
		// Sort the data by creation date, newest first
		data.sort((a, b) => new Date(b.created) - new Date(a.created));
		let table = generateShowsTable(data);
		resolve(table);
	  },
	});
  });
}

export function getAllShows() {
  return new Promise((resolve) => {
	$.ajax({
	  url: v1BaseUrl + "admin/shows/list",
	  type: "GET",
	  xhrFields: {
		withCredentials: true
	  },
	  dataType: "json",
	  success: function (data) {
		// Sort the data by creation date, newest first
		data.sort((a, b) => new Date(b.created) - new Date(a.created));
		resolve(data);
	  },
	});
  });
}

function generateShowsTable(data) {
  let table = $(`
	  <div class="w-100 hstack justify-content-between text-light mb-2" id="tch_shows"><h1>Shows</h1><div><a class="btn btn-primary" id="showUploadBtn"><i class="bi bi-upload me-1"></i> Upload</a></div></div>
	<table class="table table-striped">
	  <thead>
		<tr class="text-light">
		  <th scope="col">Cover</th>
		  <th scope="col">Title</th>
		  <!--<th scope="col">Status</th>-->
		  <th scope="col">Type</th>
		  <th scope="col">Season</th>
		  <th scope="col">Year</th>
		  <!--<th scope="col">Actions</th>-->
		</tr>
	  </thead>
	  <tbody></tbody>
	</table>
  `);

  data.forEach((show, index) => {
	let row = $(`
		<tr>
		  <th scope="row">
			<img src="${show.coverArt}" style="height: 50px;"/>
		  </th>
		  <td>
		  	<div class="vstack">
				<span class="text-light">${show.title}</span>
				<span class="fs-6">${show.subtitle}</span>
			</div>
		  </td>
		  <!--<td><span class="badge bg-danger">Unknown</span></td>-->
		  <td class="text-light">${show.type}</td>
		  <td class="text-light">${show.season}</td>
		  <td class="text-light">${show.year}</td>
		  <!--<td>
			<a style="cursor: pointer;" class="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover me-2">
			  <i class="bi bi-pencil"></i>
			  Edit
			</a>
			<a style="cursor: pointer;" class="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">
			  <i class="bi bi-trash"></i>
			  Delete
			</a>
		  </td>-->
		</tr>
	  `);
	table.find("tbody").append(row);
  });
  
  return table;
}

export const createShowModal = $(`
<div class="modal fade" id="createShowModal" tabindex="-1" aria-labelledby="createShowModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
	<div class="modal-content">
	  <div class="modal-header">
		<h5 class="modal-title" id="createCodeModalLabel">Upload Show</h5>
		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
	  </div>
	  <div class="modal-body">
		<div class="container">
		  <div id="drop_zone">
		  </div>
		  <form id="caForm" class="mt-4" method="post" enctype="multipart/form-data">
			<div class="mb-3">
			  <label for="imageFile" class="form-label">Cover Image</label><br/>
			 <label for="imageFile" class="form-label fw-bold">Must be a JPG/PNG image with a 2:3 (Poster) aspect ratio.</label>
			  <input type="file" class="form-control" id="imageFile" name="file">
			  <input type="hidden" class="d-none" id="coverArtGUID" value="">
			  <img class="d-none img-thumbnail w-25 mt-2" id="cipreview"/>
			</div>
		  </form>
		  <form id="createShowForm" class="mt-4" method="post" enctype="multipart/form-data">
			<div class="mb-3">
			  <label for="csmfdTitle" class="form-label">Title</label>
			  <input type="text" class="form-control" id="csmfdTitle" name="title">
			</div>
			<div class="mb-3">
			  <label for="csmfdSubtitle" class="form-label">Subtitle</label>
			  <span class="text-secondary">Ex: Cast A (Not Required)</span>
			  <br/>
			  <input type="text" class="form-control" id="csmfdSubtitle" name="subtitle">
			</div>
			<div class="mb-3">
			  <label for="csmfdType" class="form-label">Type</label>
			  <select class="form-control" id="csmfdType" name="type">
				<option value="Play">Play</option>
				<option value="Musical">Musical</option>
				<option value="Concert">Concert</option>
				<option value="Video">Video (Misc.)</option>
			  </select>
			</div>
			<div class="mb-3">
			  <label for="csmfdSeason" class="form-label">Season</label>
			  <select class="form-control" id="csmfdSeason" name="type">
				<option value="Spring">Spring</option>
				<option value="Summer">Summer</option>
				<option value="Fall">Fall</option>
				<option value="Winter">Winter</option>
			  </select>
			</div>
			<div class="mb-3">
			  <label for="csmfdYear" class="form-label">Year</label>
			  <input type="text" class="form-control" id="csmfdYear" name="year">
			</div>
			<input type="text" class="form-control d-none" id="csmfdVideoID" name="videoID">
			<div class="mb-3">
			  <label for="csmfdReleaseDate" class="form-label">Release Date (US Central)</label>
			  <span class="text-secondary">Use the date of the final performance. Shows are sorted by this date!</span>
			  <input type="datetime-local" class="form-control" id="csmfdReleaseDate" name="releaseAt">
			</div>
		  </form>
		</div>
	  </div>
	  <div class="modal-footer">
		  <button type="submit" class="btn btn-primary" id="csmSB">Submit</button>
		  <button class="btn btn-primary d-none" type="button" id="csmSLoader" disabled>
			<span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
			Creating Show...
		  </button>
		</div>
	</div>
  </div>
</div>
`);