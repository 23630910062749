import * as bootstrap from 'bootstrap';
import 'datatables.net-dt/css/jquery.dataTables.css';
import dt from 'datatables.net-dt';
import $ from 'jquery';
import moment from 'moment';

import { renderDashboard } from '/js/dashboard.js';
import { v1BaseUrl } from '@shared/js/apiV1.js';
import { getAllUsers, getInitials } from '/js/users.js';
import { getAllShows } from '/js/shows.js';

export async function codesTab() {
	const users = await getAllUsers();
	const userNameLookup = createUserLookup(users);
	
	const shows = await getAllShows();
	const showLookup = createShowLookup(shows);

	return new Promise((resolve) => {
		$.ajax({
			url: v1BaseUrl + "admin/codes/list",
			type: "GET",
			xhrFields: {
				withCredentials: true
			},
			dataType: "json",
			success: function (data) {
				// Sort the data by creation date, newest first
				data.sort((a, b) => new Date(b.created) - new Date(a.created));
				let table = generateCodesTable(data, userNameLookup, showLookup);
				resolve(table);
			},
		});
	});
}

export function createUserLookup(users) {
  const lookup = {};

  users.forEach(user => {
	const fullName = `${user.given_name} ${user.family_name}`;
	const initials = getInitials(user.given_name, user.family_name);
	lookup[user.guid] = { "fullName": fullName, "initals": initials };
  });

  return lookup;
}

export function createShowLookup(shows) {
	const lookup = {};

	shows.forEach(show => {
		let st = show.title;
		let sst = show.subtitle;
		lookup[show.guid] = { "title": st, "subtitle": sst };
	});

	return lookup;
}

export function generateCodesTable(data, userNameLookup, showLookup) {
  let table = $(`
	  <div class="w-100 hstack justify-content-between text-light mb-2" id="tch_shows"><h1>Codes</h1><div><a class="btn btn-primary" id="createCodeBtn"><i class="bi bi-plus-lg me-1"></i> Create</a></div></div>
	<table class="table table-striped">
	  <thead>
		<tr class="text-light">
		  <th scope="col">Code</th>
		  <th scope="col">Status</th>
		  <th scope="col">Show</th>
		  <th scope="col">Owner</th>
		  <th scope="col">Redeemed</th>
		  <th scope="col">Creator</th>
		  <th scope="col">Created</th>
		  <th scope="col">Actions</th>
		</tr>
	  </thead>
	  <tbody></tbody>
	</table>
  `);

  data.forEach((code, index) => {
	  if (code.redeemed_at == null) {
		  code.redeemed_at = "Never";
	  }
	  
	  let ownerInfo = userNameLookup[code.owner] || {};
	  let creatorInfo = userNameLookup[code.creator] || {};
	  let show = showLookup[code.content] || {};
	  
	  let ownerDisplay = ownerInfo.fullName;
	  let ownerInitals = ownerInfo.initals;
	  
	  if (ownerDisplay == undefined) {
		  ownerDisplay = "-";
	  }
	  
	  let creatorDisplay = creatorInfo.fullName;
	  let creatorInitals = creatorInfo.initals;
	  
	  let statusBadgeBg;
	  let statusBadgeText;
	  
	  switch (code.is_redeemed) {
		  case 1:
		  	statusBadgeBg = "success";
			statusBadgeText = "Redeemed";
			break;
		  case 0:
		    statusBadgeBg = "secondary";
		    statusBadgeText = "Created";
		    break;
		  default:
		    statusBadgeBg = "danger";
			statusBadgeText = "Unknown";
	  }
	  
	  if (code.owner !== null && code.is_redeemed == 0) {
		  statusBadgeBg = "warning";
		  statusBadgeText = "Released";
	  }
	  
	  let ra = code.redeemed_at;
	  let rra = "-";
	  
	  if (code.is_redeemed == 1) {
		  rra = moment(ra).fromNow();
	  }
  
  	  let ca = code.created_at;
	  let rca = "-";
	  
	  if (ca !== null) {
	  	rca = moment(ca).fromNow();
	  }
	  
	  let content = "Unknown";
		  
	  // Create the row
	  let row = $(`
		<tr>
		  <th scope="row" class="text-light font-monospace">${code.redemption_code}</th>
		  <td>
			<span class="badge bg-${statusBadgeBg}">
			  ${statusBadgeText}
			</span>
		  </td>
		  <td>
			<div class="vstack">
			  <span class="text-light">${show.title}</span>
			  <span class="fs-6">${show.subtitle}</span>
			</div>
		  </td>
		  <td class="text-light">${ownerDisplay}</td>
		  <td class="text-light"><a id="tooltip_${code.id}_a" data-bs-toggle="tooltip" data-bs-title="${ra}">${rra}</a></td>
		  <td class="text-light text-nowrap-with-tooltip" data-bs-toggle="tooltip" title="${creatorDisplay}">${creatorInitals}</td>
		  <td class="text-light"><a id="tooltip_${code.id}_b" data-bs-toggle="tooltip" data-bs-title="${ca}">${rca}</a></td>
		  <td>
			<!--<a style="cursor: pointer;" class="d-block link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover me-2 assign-btn">
			  <i class="bi bi-pencil"></i>
			  Assign
			</a>-->
			<a style="cursor: pointer;" class="d-block link-warning link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover revoke-btn" data-code="${code.redemption_code}">
			  <i class="bi bi-person-dash"></i>
			  Release
			</a>
			<a style="cursor: pointer;" class="d-block link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover delete-btn" data-code="${code.redemption_code}">
			  <i class="bi bi-trash"></i>
			  Delete
			</a>
		  </td>
		</tr>
	  `);
	  
	  // Assign click event to Assign button
	  row.find('.assign-btn').on('click', function() {
		assignCode(code.guid, code.redemption_code);
	  });
	  
	  // Assign click event to Revoke button
	  row.find('.revoke-btn').on('click', function() {
		if (confirm(`Release (unassign) code ${code.redemption_code}?`)) {
			revokeCode(code.guid, code.redemption_code);
		}
	  });
	  
	  // Assign click event to Revoke button
		row.find('.delete-btn').on('click', function() {
		  if (confirm(`Delete code ${code.redemption_code}? This cannot be undone!`)) {
			  deleteCode(code.guid, code.redemption_code);
		  }
		});
	  
	  // Append the row to the table
	  table.find("tbody").append(row);

  });
  
  return table;
}

export const createCodeModal = $(`
  <div class="modal fade" id="createCodeModal" tabindex="-1" aria-labelledby="createCodeModalLabel" aria-hidden="true">
	<div class="modal-dialog">
	  <div class="modal-content">
		<div class="modal-header">
		  <h5 class="modal-title" id="createCodeModalLabel">Create Codes</h5>
		  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
		</div>
		<div class="modal-body">
		  <form id="createCodeForm">
			<div class="mb-3">
			  <label for="showSelect" class="form-label">Select Show</label>
			  <select class="form-select" id="showSelect" required>
			  </select>
			</div>
			<div class="mb-3">
			  <label for="mediumSelect" class="form-label">Output Medium</label>
			  <select class="form-select" id="mediumSelect" required>
			    <option value="2">CSV</option>
				<option value="0">PDF</option>
				<!--<option value="3">CSV & PDF</option> Disabled due to php zip packing issue -->
				<option value="1">Email</option>
			  </select>
			</div>
			<div class="mb-3" id="qtyWrapper">
			  <label for="quantity" class="form-label">Quantity</label>
			  <input type="number" class="form-control" id="quantity" min="1" value="1" required>
			</div>
			<div class="mb-3 d-none" id="emailInputWrapper">
			  <label for="emailInput" class="form-label">Email Addresses</label>
			  <input type="text" class="form-control" id="emailInput" placeholder="Enter comma-separated email addresses">
			</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="submit" class="btn btn-primary" id="ccmSB">Submit</button>
			<button class="btn btn-primary d-none" type="button" id="ccmSLoader" disabled>
			  <span class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
			  Generating Codes...
			</button>
		</div>
	  </div>
	</div>
  </div>
`);

export async function populateCCShows() {
  let shows = await getAllShows();
  let selectList = $('#showSelect');

  // Clear the current options
  selectList.empty();

  // Populate the select list
  shows.forEach(show => {
	  let option = $("<option></option>").val(show.guid).text(`${show.title} (${show.subtitle})`);
	  if (show.subtitle == "") {
			option = $("<option></option>").val(show.guid).text(show.title);
	  }
	  $("#showSelect").append(option);
  });
}

function revokeCode(guid, code) {
	$.ajax({
		url: v1BaseUrl + "admin/codes/revoke",
		type: "POST",
		xhrFields: {
			withCredentials: true
		},
		data: {
			guid: guid
		},
		dataType: "json",
		success: function (data) {
			if (data.success) {
				renderDashboard("success", `Code ${code} has been revoked.`);
			} else {
				if (data.error) {
					alert(data.error);
				} else {
					alert("Oops! An unknown error has occured.");
				}
			}
		},
	});
}

function deleteCode(guid, code) {
	$.ajax({
		url: v1BaseUrl + "admin/codes/delete",
		type: "POST",
		xhrFields: {
			withCredentials: true
		},
		data: {
			guid: guid
		},
		dataType: "json",
		success: function (data) {
			if (data.success) {
				renderDashboard("success", `Code ${code} has been deleted.`);
			} else {
				if (data.error) {
					alert(data.error);
				} else {
					alert("Oops! An unknown error has occured.");
				}
			}
		},
	});
}

function assignCode(guid) {
	alert("This feature has not been implemented yet.");
}

function exportCode(guid) {
	alert("This feature has not been implemented yet.");
}

