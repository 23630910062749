import * as bootstrap from 'bootstrap';
import $ from 'jquery';

import { v1BaseUrl } from '@shared/js/apiV1.js';
import { loader, showLoader, hideLoader } from '/js/navigation.js';
import { mkToast } from '@shared/js/toasts.js';

export var userData = {};

export function authStatus(callback) {
  $.ajax({
	url: v1BaseUrl + "account/session",
	type: "GET",
	xhrFields: {
	  withCredentials: true
	},
	success: function (data) {
	  let status = data.status;
	  callback(data.status);
	},
	error: function (jqXHR, textStatus, errorThrown) {
	  console.error("Error: " + textStatus, errorThrown);
	  callback(true); // Avoid loosing state during tmp outage
	},
  });
}

export function promptSignIn() {
	if (window.location.href.includes("dev-proxy.net")) {
		window.location.href = "https://stagevault.dev-proxy.net/?rt=cms";
	} else {
		window.location.href = "https://stagevault.com/?rt=cms";
	}
}

export function updateUserData() {
	return new Promise((resolve, reject) => {
	  $.ajax({
		url: v1BaseUrl + 'account/info',
		type: 'GET',
		xhrFields: {
		  withCredentials: true
		},
		success: function(response) {
		  if (response.success) {
			userData = response.data;
			resolve();
		  } else {
			reject(response.error);
		  }
		},
		error: function(xhr, textStatus, errorThrown) {
		  const errorText = xhr.responseText;
		  console.error('Error:', errorText);
		  reject(errorText);
		},
	  });
	});
  }
  
function signUpUser(firstName, lastName, email, password) {
	showLoader();
	$.ajax({
	  url: v1BaseUrl + 'auth/signUp',
	  type: 'POST',
	  xhrFields: {
		withCredentials: true
	  },
	  data: {
		givenName: firstName,
		familyName: lastName,
		email: email,
		password: password,
	  },
	  success: function(response) {
		  updateUserData().then(() => {
			if (response.success) {
			  //renderLibrary();
			} else {
				hideLoader();
			  alert(response.error);
			}
		  }).catch((error) => {
			alert(error);
		  });
	  },
	  error: function(xhr, textStatus, errorThrown) {
		  hideLoader();
		const errorText = xhr.responseText;
		console.error('Error:', errorText);
	  },
	});
  }
  
  export function logoutUser() {
	  $.ajax({
		url: v1BaseUrl + 'auth/signOut', // Change this to the actual path of your PHP script
		type: 'GET',
		xhrFields: {
			withCredentials: true
		  },
		success: function(response) {
		  if (response.success) {
			  promptSignIn();
		  } else {
			  mkToast("account", "danger", "Error", response.error, 15000);
		  }
		},
		error: function(xhr, textStatus, errorThrown) {
		  // Handle the error, such as displaying an error message
		  const errorText = xhr.responseText;
		  console.error('Error:', errorText);
		  // Display the error message, for example, using an alert or showing it in a div
		},
	  });
	}

export function canStream(showId) {
	return true;
}

function createUserSettingsModal() {
	  return $(`
		<div class="modal fade" id="settingsModal" tabindex="-1" aria-labelledby="settingsModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
			<div class="modal-content">
			  <div class="modal-header">
				<h5 class="modal-title" id="settingsModalLabel">Account</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			  </div>
			  <div class="modal-body">
				<form id="settingsForm">
				  <div class="mb-3">
					<label for="givenName" class="form-label">First Name</label>
					<input type="text" class="form-control" id="givenName" value="${userData.givenName}" required>
				  </div>
				  <div class="mb-3">
					<label for="familyName" class="form-label">Last Name</label>
					<input type="text" class="form-control" id="familyName" value="${userData.familyName}" required>
				  </div>
				  <div class="mb-3">
					<label for="email" class="form-label">Email</label>
					<input type="email" class="form-control" id="email" value="${userData.email}" required>
					<span class="mt-1 fw-bold text-warning" id="emailUnverifiedWarning">This email address is not verified. If you recently changed your email, you can still sign in using your last verified email.</span>
				  </div>
				</form>
				<a class="text-warning" id="resetPasswordBtn">Reset Password</a><br>
				<span class="text-secondary">
				  To request a copy of your account data or deletion, please <a class="text-light" target="blank" href="https://help.stagevault.com/open.php">submit a data request</a>.
				</span>
			  </div>
			  <div class="modal-footer">
				<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
				<button type="button" class="btn btn-primary" id="saveSettingsBtn">Save</button>
			  </div>
			</div>
		  </div>
		</div>
	  `);
	}


export function showSettings() {
	// Remove old
	if ($("#settingsModal").length == 1) {
		$("#settingsModal").remove();
	}
	
	const settingsModal = createUserSettingsModal();
	$("body").append(settingsModal);
	
	const modalElement = $("#settingsModal");
	const settingsModalInstance = new bootstrap.Modal(modalElement);
	settingsModalInstance.show();
	
	$("#resetPasswordBtn").off("click").on('click', function(event) {
		$.ajax({
			url: v1BaseUrl + "/auth/reset",
			method: "POST",
			xhrFields: {
			  withCredentials: true
			},
			data: {
			  email: userData.email,
			},
			success: function (response) {
			  if (response.success) {
				mkToast("account", "success", "Success", "Check your email inbox for a password reset link.", 15000);
			  } else {
				if (response.error !== null) {
					mkToast("account", "danger", "Error", "An error occured while trying to request a password reset email. (" + response.error + ")", 15000);
				} else {
					mkToast("account", "danger", "Error", "An unknown error occured while trying to request a password reset email.", 15000);
				}
			  }
			},
		  });
	});
	
	if (userData.emailVerified) {
		$("#emailUnverifiedWarning").addClass('d-none');
	} else {
		$("#emailUnverifiedWarning").removeClass('d-none');
	}
	
	
	$("#saveSettingsBtn").off("click").on('click', function(event) {
		saveUserSettings();
	});
	
	// Remove the modal from the DOM when it's closed
	  modalElement.on("hidden.bs.modal", function () {
		$(this).remove();
	  });
}

function saveUserSettings() {
  $.ajax({
	url: v1BaseUrl + "account/update",
	type: "POST",
	xhrFields: {
	  withCredentials: true
	},
	data: {
	  givenName: $("#givenName").val(),
	  familyName: $("#familyName").val(),
	  email: $("#email").val(),
	},
	success: function (response) {
	  if (response.success) {
		updateUserData().then(() => {
		  // showAlert("Settings saved successfully.", "success");
		  hideSettings();
		  //renderLibrary("success", "Your account settings have been saved.");
		}).catch((error) => {
		  console.error("Error: " + error);
		});
	  } else {
		console.error("Error: " + response.error);
	  }
	},
	error: function (xhr, textStatus, errorThrown) {
	  const errorText = xhr.responseText;
	  console.error("Error:", errorText);
	},
  });
}

function hideSettings() {
  const modalElement = document.getElementById("settingsModal");
  const settingsModalInstance = bootstrap.Modal.getInstance(modalElement);
  settingsModalInstance.hide();
}

export function resendEmailConfirmation() {
	$.ajax({
		url: v1BaseUrl + "auth/confirm",
		type: "GET",
		xhrFields: {
		  withCredentials: true
		},
		success: function (response) {
		  if (response.success) {
		  	mkToast("account", "success", "Success", "We've successfully sent you another email confirmation link. Please follow the link we've sent to confirm your account.", 30000);
		  } else {
			mkToast("account", "danger", "Error", "An error occured when we tried sending you a new verification email. Please try again in a few moments. If the issue persists, contact support@stagevault.com", 30000);
		  }
		},
		error: function (xhr, textStatus, errorThrown) {
		  const errorText = xhr.responseText;
		  console.error("Error:", errorText);
		},
	  });
}