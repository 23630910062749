import $ from 'jquery';
import * as bootstrap from 'bootstrap';

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Tus from '@uppy/tus';
import ThumbnailGenerator from '@uppy/thumbnail-generator';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

import { v1BaseUrl, imageUrl } from '@shared/js/apiV1.js';
import { navBarHtml } from '/js/navigation.js';
import { showsTab, getAllShows, createShowModal } from '/js/shows.js';
import { codesTab, generateCodesTable, populateCCShows, createCodeModal, createUserLookup, createShowLookup } from '/js/codes.js';
import { usersTab, getAllUsers } from '/js/users.js';
import { analyticsTab } from '/js/analytics.js';
import { eventsTab } from '/js/events.js';

var activeTab = "shows";

export function renderDashboard(alertClass = null, alertMessage = null) {
  let app = $("#app");

  app.empty();
  app.append(navBarHtml("Administration"));

  let container = $('<div class="container pt-4 pb-5 h-100"></div>');
  let row = $('<div class="row" style="height: calc(100% - 56px);"></div>');
  
  // Sidebar
  let sidebar = $('<div class="col-md-3 col-lg-2 mb-auto d-flex flex-column bg-danger-subtle rounded-4 p-2"></div>');
  
  let tabItems = [
    {
      id: "shows",
      title: "Shows",
      icon: "bi bi-tv",
      fillIcon: "bi bi-tv-fill",
      content: showsTab
    },
    {
      id: "codes",
      title: "Codes",
      icon: "bi bi-ticket-perforated",
      fillIcon: "bi bi-ticket-perforated-fill",
      content: codesTab
    },
    {
      id: "users",
      title: "Users",
      icon: "bi bi-people",
      fillIcon: "bi bi-people-fill",
      content: usersTab
    },
    {
      id: "analytics",
      title: "Analytics",
      icon: "bi bi-bar-chart-line",
      fillIcon: "bi bi-bar-chart-line-fill",
      content: analyticsTab
    },
    {
      id: "events",
      title: "Audit Log",
      icon: "bi bi-binoculars",
      fillIcon: "bi bi-binoculars-fill",
      content: eventsTab
    },
  ];

  tabItems.forEach((item) => {
    let tabLink = $(`
      <a class="nav-link text-white d-flex align-items-center m-1 px-2 py-1 rounded-3" style="cursor: pointer;" data-tab="${item.id}" style="background-color: transparent;">
        <i class="${item.icon} me-2"></i>
        ${item.title}
      </a>
    `);
    tabLink.on('click', function() {
      activeTab = item.id;
      showTabContent(item.id);
    });
    sidebar.append(tabLink);
  });

  // Content area
  let content = $('<div id="contentArea" class="col-md-9 col-lg-10 ps-4" style="overflow-y: auto; height: 100%;"></div>');
  
  tabItems.forEach((item) => {
    let table = $(`
      <table class="table table-striped d-none" id="${item.id}-table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Column 1</th>
            <th scope="col">Column 2</th>
          </tr>
        </thead>
        <tbody>
          <!-- Add your table rows here -->
        </tbody>
      </table>
    `);
    content.append(table);
  });

  row.append(sidebar, content);
  container.append(row);
  app.append(container);

  (async function() {
    // Show the first tab by default
    await showTabContent(activeTab);
  })();
  
  async function showTabContent(tabId) {
    const tabItem = tabItems.find((item) => item.id === tabId);
  
    if (tabItem) {
      // Create a spinner element
      const spinner = $('<div class="d-flex justify-content-center mt-5"><div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></div>');
  
      content.empty();
      content.append(spinner); // Add spinner to the content area
      
      // Sidebar
      
      sidebar.find('.nav-link').each(function () {
        $(this).removeClass('active').css('background-color', 'transparent');
        let currentItem = tabItems.find((item) => item.id === $(this).data('tab'));
        $(this).find('i').removeClass(currentItem.fillIcon).addClass(currentItem.icon);
      });
      
      let activeItem = tabItems.find((item) => item.id === tabId);
      sidebar
        .find(`[data-tab="${tabId}"]`)
        .addClass('active')
        .css('background-color', '#dc3545')
        .find('i')
        .removeClass(activeItem.icon)
        .addClass(activeItem.fillIcon);
  
      const newContent = await tabItem.content(); // Await the content
  
      content.empty(); // Clear the content area, including the spinner
      content.append(newContent); // Add the new content
      
      postSelect(activeTab);
      
      // $('[data-bs-toggle="tooltip"]').tooltip(); // Render any tooltips
    }
    
  }

}

async function postSelect(item) {
  // console.log("Event Handler Called for: " + item);
  switch (item) {
    case "codes":
    
      let users = await getAllUsers();
      let userNameLookup = createUserLookup(users);
      
      let shows = await getAllShows();
      let showLookup = createShowLookup(shows);
  
      if ($("#createCodeModal").length) {
        populateCCShows();
      } else {
        $("body").append(createCodeModal);
        populateCCShows();
      }
      
      const modalElement = document.getElementById("createCodeModal");
      const modalInstance = new bootstrap.Modal(modalElement);

      // Show the Create Code modal when the Create button is clicked
      $("#createCodeBtn").off("click").on("click", function () {
        modalInstance.show();
      });
    
      // Show/hide the email input field based on the selected output medium
      $("#mediumSelect").off("change").on("change", function () {
        if ($(this).val() == 1) {
        $("#emailInputWrapper").removeClass("d-none");
        $("#qtyWrapper").addClass("d-none");
        } else {
        $("#emailInputWrapper").addClass("d-none");
        $("#qtyWrapper").removeClass("d-none");
        }
      });
      
      // Handle the form submission
      $("#ccmSB").off("click").on("click", async function (e) {
        
        // UI
        $("#ccmSB").addClass("d-none");
        $("#ccmSLoader").removeClass("d-none");
        
        function isValidEmail(email) {
            var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            return emailRegex.test(email);
        }
        
        if ($("#mediumSelect").val() == 1) {
            var inputValue = $("#emailInput").val(); // Replace '#emailInput' with the actual ID of your input element
            var emails = inputValue.split(",").map(function(email) {
                return email.trim();
            });
        
            var invalidEmails = emails.filter(function(email) {
                return !isValidEmail(email);
            });
        
            if (invalidEmails.length > 0) {
                // UI
                $("#ccmSB").removeClass("d-none");
                $("#ccmSLoader").addClass("d-none");
                alert("The following email addresses are not valid: " + invalidEmails.join(", "));
            } else {
                // Proceed with valid emails
            }
        } else {
          let emails = [];
        }
        
        emails = JSON.stringify(emails)
        
        e.preventDefault();
        return new Promise((resolve) => {
          $.ajax({
            url: v1BaseUrl + "admin/codes/create",
            type: "POST",
            xhrFields: {
              withCredentials: true
            },
            data: {
              show: $("#showSelect").val(),
              quantity: $("#quantity").val(),
              medium: $("#mediumSelect").val(),
              emails: emails
            },
            dataType: "json",
            success: function (data) {
              if (!data.success) {
                // UI
                $("#ccmSB").removeClass("d-none");
                $("#ccmSLoader").addClass("d-none");
                
                alert("Oops! An error occured while trying to create codes.");
                return;
              } else {
                // UI
                $("#ccmSB").removeClass("d-none");
                $("#ccmSLoader").addClass("d-none");
                
                // Form
                $("#quantity").val(1);
                $("#emailInput").val("");
                
                // Close Modal
                modalInstance.hide();
                renderDashboard();
                
                if (data.zip) {
                  window.location.href = data.zip;
                }
                if (data.csv) {
                  window.location.href = data.csv;
                }
                if (data.pdf) {
                  window.location.href = data.pdf;
                }
              }
            },
          });
        });
      });
      
      // Bind the event listener to the revoke buttons
      setTimeout(bindRevokeButtonClickEvent, 500);
        
      break;
    case "shows": 
      if (!$("#createShowModal").length) {
          $("body").append(createShowModal);
          
          $("#csmSB").addClass("disabled");
          
          var dropZone = $('#drop_zone');
          var videoFileInput = $('#videoFile');
          
          const uppy = new Uppy({
              meta: {
                  // Meta data to send along with the file
              },
              debug: true,
              restrictions: {
                  maxNumberOfFiles: 1,
                  maxFileSize: 32104880537.6, // 29.9 GB
                  allowedFileTypes: ['video/*']
              },
              autoProceed: true
          });
          
          var requiresignedurls = btoa('true'); // Encoding boolean 'true' as a string

          uppy.use(Tus, {
              endpoint: "https://stagevault-uploader.dbapps.workers.dev/", // The Tus endpoint to upload to
              chunkSize: 52428800,
              headers: {
                  'Upload-Metadata': 'requiresignedurls ' + requiresignedurls,
              },
              retryDelays: [0, 1000, 3000, 5000], // Retry delays in milliseconds
          });
          
          uppy.use(Dashboard, {
              target: '#drop_zone',
              inline: true,
              showProgressDetails: true,
              theme: 'dark',
              height: 300,
              doneButtonHandler: null
          });
          
          uppy.on('upload-success', (file, response) => {
            const mediaIdWithQuery = response.uploadURL.split('/').pop(); // Extract the mediaId from the uploadURL
            const mediaId = mediaIdWithQuery.split('?')[0]; // Remove the query parameters
            if (mediaId) {
              $("#csmfdVideoID").val(mediaId);
            }
          });
          
          uppy.on('complete', (result) => {
              console.log('Upload complete! We’ve uploaded these files:', result.successful);
          
              result.successful.forEach(file => {
                  console.log(file.name, file.uploadURL);
                  $("#csmSB").removeClass("disabled");
              });
          });

      
      }
      
      function reqUrl(file, callback) {
          var fileMetadata = btoa('filename ' + file.name); // Encode filename metadata in base64
          var uploadLength = file.size; // Size of the file
      
          $.ajax({
              url: v1BaseUrl + "admin/shows/upload",
              beforeSend: function(xhr) {
                  // Set the necessary headers
                  xhr.setRequestHeader("Tus-Resumable", "1.1.3");
                  xhr.setRequestHeader("Upload-Length", uploadLength);
                  xhr.setRequestHeader("Upload-Metadata", fileMetadata);
              },
              type: 'HEAD',
              success: function(data, textStatus, request){
                  console.log(request.getAllResponseHeaders());
                  var location = request.getResponseHeader('Location');
                  if (location) {
                      callback(null, location);
                  } else {
                      callback(new Error("Failed to generate upload URL."), null);
                  }
              },
              error: function(response) {
                  callback(new Error("Server Error"), null);
              }
          });
      }




      
      let showModalElement = document.getElementById("createShowModal");
      let showModalInstance = new bootstrap.Modal(showModalElement);
      
      showModalElement.addEventListener('show.bs.modal', function (event) {
        $("#csmSB").addClass("disabled");
        $('form', this).trigger('reset');
        $("#cipreview").attr("src", "");
        $("#cipreview").addClass("d-none");
        uppy.cancelAll();
      });
      
      // Show the Create Show modal when the Upload button is clicked
      $("#showUploadBtn").off("click").on("click", function () {
        showModalInstance.show();
      });
      
      $('#imageFile').off('change').on('change', function(e) {
          var file = this.files[0];
          
          // check file type
          if (!['image/jpeg', 'image/png'].includes(file.type)) {
              alert("Cover image is not a JPEG or PNG image.");
              this.value = '';  // clear the file input
              return;
          }
          
          var img = new Image();
          img.onload = function() {
              // calculate aspect ratio
              var aspectRatio = this.width / this.height;
          
              // check aspect ratio
              if (Math.abs(aspectRatio - (2/3)) > 0.01) { // allow some tolerance
                  alert("Cover image aspect ratio is not valid.");
                  $('#imageFile').val('');  // clear the file input
                  return;
              }
          
              // Get the form element
              var formElement = $('#caForm')[0];  // or $('#createShowForm').get(0);
          
              // Create a FormData object
              var formData = new FormData(formElement);
          
              uploadCoverArt(formData);
          };
          
          img.src = URL.createObjectURL(file);
      });

      $('#csmSB').off('click').on('click', function(e) {
          e.preventDefault();
          
          // UI
          $("#csmSB").addClass("d-none");
          $("#csmSLoader").removeClass("d-none");
        
          // Get the form element
          var formElement = $('#createShowForm')[0];  // or $('#createShowForm').get(0);
          
          // Create a FormData object
          var formData = new FormData(formElement);
          
          let imgurl = null;
          
          if ($("#coverArtGUID").val() !== "") {
            imgurl = imageUrl($("#coverArtGUID").val());
          }
      
          $.ajax({
              url: v1BaseUrl + 'admin/shows/create',  // your upload script
              xhrFields: {
                  withCredentials: true
              },
              type: 'POST',
              data: {
                title: $("#csmfdTitle").val(),
                subtitle: $("#csmfdSubtitle").val(),
                type: $("#csmfdType").val(),
                season: $("#csmfdSeason").val(),
                year: $("#csmfdYear").val(),
                videoID: $("#csmfdVideoID").val(),
                releaseAt: $("#csmfdReleaseDate").val(),
                coverArt: imgurl,
              },
              dataType: 'json',
              success: function(response) {
                  // UI
                  $("#csmSB").removeClass("d-none");
                  $("#csmSLoader").addClass("d-none");
                  if (response.success) {
                      showModalInstance.hide();
                      renderDashboard();
                  } else {
                    if (response.error) {
                      alert(response.error);
                    } else {
                      alert("An unknown error occured.");
                    }
                  }
              },
              error: function(response) {
                // UI
                $("#csmSB").removeClass("d-none");
                $("#csmSLoader").addClass("d-none");
                alert("Server Error");
              }
          });
      });
      
      function uploadCoverArt(formData) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: v1BaseUrl + 'content/upload?type=image',  // your upload script
                xhrFields: {
                    withCredentials: true
                },
                type: 'POST',
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                success: function(response) {
                    if (response.success) {
                        $("#cipreview").attr("src", imageUrl(response.guid));
                        $("#cipreview").removeClass("d-none");
                        $("#coverArtGUID").val(response.guid);
                        resolve(response); // Resolve promise if upload is successful
                    } else {
                        reject('Upload failed: ' + response); // Reject promise if upload failed
                    }
                },
                error: function(response) {
                    reject('Upload failed: ' + response); // Reject promise if upload failed
                }
            });
        });
      }
      
      break;
  }
}

function bindRevokeButtonClickEvent() {
  $(".revokeBtn").off("click").on("click", function () {    const codeToRevoke = $(this).data("code");
    showRevokeCodeModal(codeToRevoke);
  });
}


async function showRevokeCodeModal(codeToRevoke) {
  const result = confirm(`Are you sure you want to revoke the code ${codeToRevoke}?`);

  if (result) {
    try {
      const response = await $.ajax({
        url: `${v1BaseUrl}admin/codes/delete`,
        type: "POST",
        xhrFields: {
          withCredentials: true
        },
        data: {
          code: codeToRevoke
        },
        dataType: "json",
      });

      if (response.success) {
        alert(`Code ${codeToRevoke} has been revoked.`);
        renderDashboard("success", `Code ${codeToRevoke} has been revoked.`);
      } else {
        alert("Oops! An error occurred while trying to revoke the code.");
      }
    } catch (error) {
      alert("Oops! An error occurred while trying to revoke the code.");
      console.error(error);
    }
  }
}

