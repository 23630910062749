import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import moment from 'moment';

import { v1BaseUrl } from '@shared/js/apiV1.js';

export function analyticsTab() {
  return new Promise((resolve) => {
	$.ajax({
	  url: v1BaseUrl + "admin/analytics/shows",
	  type: "GET",
	  xhrFields: {
		withCredentials: true
	  },
	  dataType: "json",
	  success: function (data) {
		let table = generateAnalyticsTable(data);
		resolve(table);
	  },
	});
  });
}

function generateAnalyticsTable(data) {
  let totalMinutesWatched = 0;

  data.data.shows.forEach((show) => {
	totalMinutesWatched += show.analytics.minutesViewed;
  });

  let table = $(`
	<div class="w-100 hstack justify-content-between text-light mb-2" id="tch_shows">
	  <h1>Analytics</h1>
	  <h4>Total: ${totalMinutesWatched} minutes</h4>
	</div>
	<table class="table table-striped">
	  <thead>
		<tr class="text-light">
		  <th scope="col">Cover</th>
		  <th scope="col">Show Title</th>
		  <th scope="col">Minutes Watched (Last 30 days)</th>
		</tr>
	  </thead>
	  <tbody></tbody>
	</table>
  `);

  data.data.shows.forEach((show) => {
	let row = $(`
	  <tr>
		<th>
			<img src="${show.metadata.coverArt}" style="height: 50px;"/>
		  </th>
		  <td>
			  <div class="vstack">
				<span class="text-light">${show.metadata.title}</span>
				<span class="fs-6">${show.metadata.subtitle}</span>
			</div>
		  </td>
		<td class="text-light">${show.analytics.minutesViewed}</td>
	  </tr>
	`);
	table.find("tbody").append(row);
  });

  return table;
}
